import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import MultipleIntegration from "@src/icons/screenshots/multiple-integrations.png"
import GoogleSheetsIcon from "@src/icons/v2/google-sheets-icon.svg"
import PinterestIcon from "@src/icons/v2/pinterest-icon.svg"
import AsanaIcon from "@src/icons/v2/asana-icon.svg"
import ShopifyIcon from "@src/icons/v2/shopify-icon.svg"
import TimeConsumingIcon from "@src/icons/v2/time-consuming-integrations.svg"
import ManagingSourcesIcon from "@src/icons/v2/managing-sources-icon.svg"
import ComplexMaintenanceIcon from "@src/icons/v2/complex-maintenance-icon.svg"
import OG from "@src/icons/og/OG_Integrations_Pack.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Row from "../components/Row"
import Features from "./components/Features"

const MultipleIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Integrate and optimize the workflow"
      description="Transform data, reduce time spent due to manual processes, scale your integrations with the lowcode code editor, create customized workflows based on triggers or based on time, and can help your business for the faster go-to-market. 🚀"
      url="https://canonic.dev/features/multiple-integrations"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Integrate and optimize the workflow"
        subtitle="Transform data, reduce time spent due to manual processes, scale your integrations with the lowcode code editor, create customized workflows based on triggers or based on time, and can help your business for the faster go-to-market. 🚀"
        screenshot={MultipleIntegration}
      />
      <Row>
        <h2 className={styles.title}>Benefits</h2>
        <p className={styles.subtitle}>
          Integrate services easily to work efficiently
        </p>
        <Features
          horizontal
          numPerRow={2}
          features={[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              subtitle: `Create a schedule trigger that fetches the metrics from YouTube likes and fill up the stats in a google sheet. Canonic then triggers a slack as well as email integration to notify end users with sheet link.`,
              link: {
                title: "Know more >",
                url: `https://dev.to/canonic/youtube-to-google-sheets-how-to-import-youtube-public-data-1b5h`,
              },
            },
            {
              icon: PinterestIcon,
              title: "Pinterest",
              subtitle: `Create a trigger for every time a row is added in an air table with the "pin" data. Canonic then sends this to slack for approval. After getting the approval back, it's posted on Pinterest as a "pin".`,
              link: {
                title: "Know more >",
                url: `https://dev.to/canonic/how-to-create-an-awesome-slack-approval-workflow-1gdg`,
              },
            },
            {
              icon: ShopifyIcon,
              title: "Shopify",
              subtitle: `Create a trigger which updates the inventory on Shopify whenever a new row is added to the inventory sheet.`,
              link: {
                title: "Know more >",
                url: `https://dev.to/canonic/scheduled-exports-from-shopify-to-google-sheets-495l`,
              },
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              subtitle: `Create a trigger that is triggered every time a ticket is added to Asana. Therefore, every time a ticket is created on Asana, an issue is created on GitHub.`,
              link: {
                title: "Know more >",
                url: `https://dev.to/canonic/integrate-github-with-asana-to-track-issues-in-repositories-6p1`,
              },
            },
          ]}
        />
      </Row>
      <Row>
        <h2 className={styles.title}>Benefits</h2>
        <p className={styles.subtitle}>One-stop solution for</p>
        <Features
          numPerRow={3}
          features={[
            {
              icon: TimeConsumingIcon,
              title: "Time-consuming integrations ",
              subtitle:
                "Save the complex task of coding and integrating each API by directly adding them to your stack with Canonic",
            },
            {
              icon: ManagingSourcesIcon,
              title: "Manage multiple sources of APIs",
              subtitle:
                "Canonic provides you with a single dashboard to manage your sources and APIs saving you the hassle of going to multiple platforms",
            },
            {
              icon: ComplexMaintenanceIcon,
              title: "Complex maintenance and resources ",
              subtitle:
                "Coding and maintaining integrations is resource consuming, Canonic allows you to save time-cost as well as engineering effort",
            },
          ]}
        />
      </Row>
      <Callout location={location} title="multipleIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default MultipleIntegrations
